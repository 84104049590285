import React from "react";

interface SortUpArrowProps {
  width?: string;
  height?: string;
}

const SortUpArrow: React.FC<SortUpArrowProps> = ({
  width = "6px",
  height = "6px",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 6 6"
    fill="none"
  >
    <path
      d="M3.5655 0.68461C3.5945 0.71286 3.7185 0.81953 3.8205 0.9189C4.462 1.50146 5.512 3.02119 5.8325 3.81661C5.884 3.93741 5.993 4.24282 6 4.40599C6 4.56235 5.964 4.7114 5.891 4.85363C5.789 5.03093 5.6285 5.17316 5.439 5.2511C5.3075 5.30127 4.914 5.3792 4.907 5.3792C4.4765 5.45714 3.777 5.5 3.004 5.5C2.2675 5.5 1.5965 5.45714 1.1595 5.39333C1.1525 5.38602 0.6635 5.30809 0.496 5.22285C0.19 5.06649 0 4.76108 0 4.43424V4.40599C0.0075 4.19313 0.1975 3.74549 0.2045 3.74549C0.5255 3.99294 1.523 2.50828 2.1865 1.91159C2.1865 1.91159 2.357 1.74355 2.4635 1.67048C2.6165 1.5565 2.806 1.5 2.9965 1.5C3.207 1.5 3.404 1.56381 3.5655 1.68461Z"
      fill="#030229"
    />
  </svg>
);

export default SortUpArrow;

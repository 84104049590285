import React from "react";
import { Box, Typography } from "@mui/material";

interface ErrorProps {
  messages: string[];
  customStyle?: any;
}

const Error: React.FC<ErrorProps> = ({ messages, customStyle }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "14px 20px",
        gap: "8px",
        maxWidth: "554px",
        bgcolor: "error.light",
        borderRadius: "40px",
        marginTop: "12px",
        width: "100%",
        ...customStyle,
      }}
    >
      {messages.map((msg, index) => (
        <Typography key={index} variant="body2" color="error">
          {msg}
        </Typography>
      ))}
    </Box>
  );
};

export default Error;

// This placeholder data will be removed once we retrieve the tenancy data from the backend API.

export interface Tenant {
  buildingName: string;
  apartmentNumber: string;
  tenantName: string;
  leasePeriod: string;
}

export const tenancyData: Tenant[] = [
  {
    buildingName: "Palm Residences",
    apartmentNumber: "Apt. 101",
    tenantName: "Jessica Taylor",
    leasePeriod: "01-Jan-2024 - 31-Dec-2024",
  },
  {
    buildingName: "Maple Grove Compound",
    apartmentNumber: "Unit 204",
    tenantName: "Jane Smith",
    leasePeriod: "01-Mar-2023 - 28-Feb-2024",
  },
  {
    buildingName: "Lakeview Towers",
    apartmentNumber: "Apt. 305",
    tenantName: "Adam Lee",
    leasePeriod: "01-Apr-2024 - 31-Mar-2025",
  },
  {
    buildingName: "Green Valley Residences",
    apartmentNumber: "Unit 501",
    tenantName: "Sarah Kim",
    leasePeriod: "01-Feb-2024 - 31-Jan-2025",
  },
  {
    buildingName: "Blue Lagoon Apartments",
    apartmentNumber: "Apt. 23A",
    tenantName: "David Liu",
    leasePeriod: "15-Apr-2023 - 14-Apr-2024",
  },
  {
    buildingName: "Sunset Villas",
    apartmentNumber: "Villa 12",
    tenantName: "Emma Wong",
    leasePeriod: "01-Jun-2023 - 31-May-2024",
  },
  {
    buildingName: "City View Towers",
    apartmentNumber: "Unit 322",
    tenantName: "Jack Miller",
    leasePeriod: "01-Jul-2023 - 30-Jun-2024",
  },
  {
    buildingName: "Harborfront Condos",
    apartmentNumber: "Unit 204",
    tenantName: "Lucy Brown",
    leasePeriod: "01-Sep-2023 - 31-Aug-2024",
  },
  {
    buildingName: "Mountain Ridge Estates",
    apartmentNumber: "Unit 202",
    tenantName: "Peter Clark",
    leasePeriod: "01-Nov-2023 - 31-Oct-2024",
  },
  {
    buildingName: "Seaside Apartments",
    apartmentNumber: "Apt. 609",
    tenantName: "Karen Taylor",
    leasePeriod: "01-Jan-2024 - 31-Dec-2024",
  },
];

import { Box, Button, Typography } from "@mui/material";
import Circle from "../../../../../assets/icons/ui/Circle";
import VisaImage from "../../../../../assets/icons/ui/VisaImage";
import classNames from "classnames";
import Modal from "../../../../shared/Modal/Modal";
import CancelSubscription from "./CancelSubscription/CancelSubscription";
import CancelSubscriptionReason, {
  CancellationReason,
} from "./CancelSubscriptionReason/CancelSubscriptionReason";
import CancelSubscriptionConfirm from "./CancelSubscriptionConfirm/CancelSubscriptionConfirm";
import Cross from "../../../../../assets/icons/ui/Cross";
import Plans from "../../../../registration/Plans/Plans";
import { useState, useEffect } from "react";
import { getSubscriptions } from "../../../../../api/plans";
import { cancelSubscription } from "../../../../../api/plans";
import styles from "./ActiveSubscription.module.css";
import { convertDate } from "../../../../../utils/inputFIeldHelper";

interface Subscription {
  id: number;
  customer: number;
  customer_name: string;
  start_date: string;
  status: string;
  status_name: string;
  end_date: string | null;
  memo: string | null;
  created_at: string;
  next_billing_date: string;
  plan: {
    id: number;
    name: string;
    description: string;
    price: number;
    billing_cycle: string;
    features: string[];
  };
}

const ActiveSubscription = () => {
  const [planVisible, setPlanVisible] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);
  const [flowType, setFlowType] = useState<"cancel" | null>(null);
  const [subscriptions, setSubscriptions] = useState<Subscription[] | null>(
    null,
  );

  const openPlan = () => setPlanVisible(true);
  const closePlan = () => setPlanVisible(false);

  const handleCancelSubscriptionClick = () => {
    setFlowType("cancel");
    openModal(0);
  };

  const openModal = (index: number) => setModalIndex(index);
  const closeModal = () => {
    setModalIndex(null);
    setFlowType(null);
  };

  const [cancelReason, setCancelReason] = useState<CancellationReason | null>(
    null,
  );
  const [cancelError, setCancelError] = useState<string>("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const modals = {
    cancel: [
      {
        title: "Confirmation for Cancel Subscription",
        description: (
          <CancelSubscription
            isRequested={subscriptions?.[0]?.status === "Pending"}
            endOfBillingCycle={subscriptions?.[0]?.next_billing_date}
            endDate={subscriptions?.[0]?.end_date}
          />
        ),
        primaryBtnText: "Proceed with Cancellation",
        nextModalIndex: 1,
      },
      {
        title: "Reason for Cancellation",
        description: (
          <CancelSubscriptionReason
            cancelReason={cancelReason}
            setCancelReason={reason => setCancelReason(reason)}
            cancelError={cancelError}
            setCancelError={err => setCancelError(err)}
          />
        ),
        primaryBtnText: "Submit",
        nextModalIndex: 2,
      },
      {
        title: "Cancellation Confirmation",
        description: (
          <CancelSubscriptionConfirm endDate={subscriptionEndDate} />
        ),
        primaryBtnText: "Back to Dashboard",
      },
    ],
  };

  const handlePrimaryBtnClick = async () => {
    const currentModal = modals[flowType!][modalIndex!];
    if (currentModal.nextModalIndex !== undefined) {
      if (currentModal.nextModalIndex === 2) {
        if (cancelReason) {
          setLoading(true);
          const data = {
            cancellation_reason: cancelReason?.id,
            memo: cancelReason?.reason,
          };

          try {
            if (subscriptions) {
              const res = await cancelSubscription(
                subscriptions?.[0]?.id,
                data,
              );
              setSubscriptionEndDate(res.subscription.end_date);
            }
            setLoading(false);
            openModal(currentModal.nextModalIndex);
          } catch (error: any) {
            setCancelError(
              "Failed to cancel subscription: " +
                (error.response.data.detail ||
                  error.response.data.non_field_errors[0]),
            );
          }
        } else {
          setCancelError(
            "Failed to cancel subscription: No cancel reason selected",
          );
        }
        setLoading(false);
      } else {
        openModal(currentModal.nextModalIndex);
      }
    } else {
      closeModal();
    }
  };

  const currentModal = flowType ? modals[flowType][modalIndex!] : null;

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const res = await getSubscriptions();
        setSubscriptions(res);
      } catch (err) {
        console.log("Error fetching subscriptions:", err);
        setPlanVisible(true);
      }
    };

    fetchSubscriptions();
  }, []);

  return planVisible ? (
    <Plans showStepper={false} customStyle={{ marginTop: "0 !important" }} />
  ) : (
    <>
      <Box className={styles.container}>
        {subscriptions &&
          subscriptions.map(subscription => (
            <>
              <Box key={subscription.id} className={styles.section1}>
                <Box className={styles.subscriptionContainer}>
                  <Box className={styles.subscriptionInfo}>
                    <Typography className={styles.activeSubscriptionText}>
                      Active Subscription
                    </Typography>
                    <Box className={styles.align}>
                      <Typography className={styles.price}>
                        ${subscription.plan.price}
                      </Typography>{" "}
                      <Typography className={styles.duration}>
                        /{subscription.plan.billing_cycle.toLowerCase()}
                      </Typography>{" "}
                    </Box>
                  </Box>
                  <Box className={styles.buttonContainer}>
                    <Button className={styles.starterBtn}>
                      {subscription.plan.name}
                    </Button>
                    <Button className={classNames(styles.activeBtn)}>
                      <Circle height="8px" width="8px" fill="#72D400" />{" "}
                      {subscription.status}
                    </Button>
                  </Box>
                </Box>

                <Box className={styles.paymentContainer}>
                  <Box className={styles.paymentInfo}>
                    <Typography className={styles.nextPaymentText}>
                      Next Payment
                    </Typography>
                    <Box className={styles.align2}>
                      <VisaImage />
                      <Typography className={styles.visaInfoText}>
                        Visa ending with 3456{" "}
                      </Typography>
                      <Typography className={styles.visaInfoDate}>
                        {new Date(
                          subscription.next_billing_date,
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={styles.planBtnContainer}>
                    <Button className={styles.changePlanBtn} onClick={openPlan}>
                      Change Plan
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box className={styles.subscriptionStatusText}>
                "Your subscription will end on{" "}
                {convertDate(subscription.end_date)}. Go to Subscription
                Management to re-activate before this date."
                <Box
                  sx={{
                    cursor: "pointer",
                    background: "#F0F0F0",
                    height: "24px",
                    width: "24px",
                    borderRadius: "100%",
                  }}
                >
                  <Cross height={24} width={24} />
                </Box>
              </Box>
            </>
          ))}

        <Box className={styles.section2}>
          <Typography className={styles.paymentInfoText}>
            Payment info
          </Typography>
          <Box className={styles.cardContainer}>
            <Typography className={styles.cardDetailsText}>
              Card Details
            </Typography>
            <Box className={styles.align2}>
              <VisaImage />
              <Typography className={styles.cardInfo}>
                Visa ending with 3456
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={styles.section3}>
          <Button
            className={classNames(
              styles.btn,
              styles.cancelSubscriptionBtn,
              styles.reactivateBtn,
            )}
            onClick={handleCancelSubscriptionClick}
          >
            Cancel Subscription
          </Button>
          <Button className={classNames(styles.btn, styles.managePaymentsBtn)}>
            Manage Payments
          </Button>
        </Box>

        {currentModal && (
          <Modal
            open={true}
            onClose={closeModal}
            showClose={false}
            title={currentModal.title}
            description={currentModal.description}
            showActions={true}
            primaryBtnText={currentModal.primaryBtnText}
            onClickPrimaryBtn={handlePrimaryBtnClick}
            secondaryBtnText="Cancel"
            onClickSecondaryBtn={closeModal}
            loading={loading}
          />
        )}
      </Box>
    </>
  );
};

export default ActiveSubscription;

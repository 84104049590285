import { Typography, Box, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { getCancellationReasons } from "../../../../../../api/plans";
import Error from "../../../../../shared/Error/Error";
import styles from "./CancelSubscriptionReason.module.css";

export interface CancellationReason {
  id: number;
  reason?: string;
}

interface CancelSubscriptionReasonProps {
  cancelReason: CancellationReason | null;
  setCancelReason: (reason: CancellationReason | null) => void;
  cancelError: string;
  setCancelError: (err: string) => void;
}

const CancelSubscriptionReason: React.FC<CancelSubscriptionReasonProps> = ({
  cancelReason,
  setCancelReason,
  cancelError,
  setCancelError,
}) => {
  const [options, setOptions] = useState<CancellationReason[]>([]);

  useEffect(() => {
    const fetchCancellationReasons = async () => {
      try {
        const data = await getCancellationReasons();
        setOptions(data);
      } catch (error) {
        setCancelError("Failed to fetch subscription cancellation reasons");
      }
    };

    fetchCancellationReasons();
  }, []);

  const handleCheckboxChange = (selectedReason: CancellationReason) => {
    if (cancelReason?.id === selectedReason.id) {
      setCancelReason(null);
    } else {
      setCancelReason(selectedReason);
    }
  };

  return (
    <Box className={styles.section}>
      <Typography className={styles.feedbackText}>Feedback Options</Typography>
      {options.map(option => (
        <Box key={option.id} className={styles.feedbackOption}>
          <FormControlLabel
            control={
              <Checkbox
                checked={cancelReason?.id === option.id}
                onChange={() => handleCheckboxChange(option)}
                sx={{
                  "&.Mui-checked": {
                    color: "#AAA",
                  },
                  "&.MuiCheckbox-root": {
                    color: "#AAA",
                  },
                }}
              />
            }
            label={
              <Typography className={styles.feedback}>
                {option.reason}
              </Typography>
            }
          />
        </Box>
      ))}
      {cancelError && (
        <Error messages={[cancelError]} customStyle={{ maxWidth: "663px" }} />
      )}
    </Box>
  );
};

export default CancelSubscriptionReason;

import React, { useEffect } from "react";
import { useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SortDownArrow from "../../../../../assets/icons/ui/SortDownArrow";
import SortUpArrow from "../../../../../assets/icons/ui/SortUpArrow";
import classNames from "classnames";
import getBillingHistory, {
  getSubscriptions,
  Transaction,
} from "../../../../../api/plans";
import { convertDate } from "../../../../../utils/inputFIeldHelper";
import styles from "./BillingHistory.module.css";

type SortDirection = "ascending" | "descending";

interface SortConfig {
  key: keyof Transaction;
  direction: SortDirection;
}

const initialSortConfig: SortConfig = {
  key: "id",
  direction: "ascending",
};

const BillingHistory = () => {
  const [billingHistory, setBillingHistory] = useState<Transaction[]>([]);

  const [sortConfig, setSortConfig] = useState<SortConfig>(initialSortConfig);

  const sortedRows = React.useMemo(() => {
    const sortableRows = [...billingHistory];

    if (sortConfig) {
      sortableRows.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableRows;
  }, [billingHistory, sortConfig]);

  const sortData = (key: keyof Transaction) => {
    let direction: SortDirection = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const displaySortArrow = (key: keyof Transaction) => {
    if (sortConfig.key !== key) {
      return <SortDownArrow />;
    }

    return sortConfig.direction === "ascending" ? (
      <SortDownArrow />
    ) : (
      <SortUpArrow />
    );
  };

  useEffect(() => {
    const fetchBillingHistory = async () => {
      try {
        const subscriptions = await getSubscriptions();
        if (subscriptions.length > 0) {
          const id = subscriptions[0]?.id;
          const data = await getBillingHistory(id);
          setBillingHistory(data);
        }
      } catch (error) {
        console.log("Error fetching billing history:", error);
      }
    };

    fetchBillingHistory();
  }, []);

  return (
    <Box className={styles.billingSection}>
      {billingHistory.length > 0 ? (
        <>
          <Typography className={styles.billingHistory}>
            Billing History
          </Typography>
          <Typography className={styles.billingHistoryDescription}>
            Effortlessly handle your billing and invoices right here.
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={styles.tableHeader}
                    onClick={() => sortData("id")}
                  >
                    Invoice ID {displaySortArrow("id")}
                  </TableCell>
                  <TableCell
                    className={styles.tableHeader}
                    onClick={() => sortData("date")}
                  >
                    Billing Date {displaySortArrow("date")}
                  </TableCell>
                  <TableCell
                    className={styles.tableHeader}
                    onClick={() => sortData("type")}
                  >
                    Plan {displaySortArrow("type")}
                  </TableCell>
                  <TableCell
                    className={styles.tableHeader}
                    onClick={() => sortData("amount")}
                    align="center"
                  >
                    Amount {displaySortArrow("amount")}
                  </TableCell>
                  <TableCell
                    className={styles.tableHeader}
                    onClick={() => sortData("entry_type")}
                    align="center"
                  >
                    Status {displaySortArrow("entry_type")}
                  </TableCell>
                  <TableCell className={styles.tableHeader} align="center">
                    Download Invoice
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row: Transaction) => (
                  <>
                    <TableRow
                      key={row.id}
                      className={`${styles.tableRow} ${styles.rowSpacing}`}
                      sx={{
                        marginBottom: "15px",
                        display: "table-row",
                      }}
                    >
                      <TableCell
                        className={styles.tableData}
                        sx={{
                          borderTopLeftRadius: "9px",
                          borderBottomLeftRadius: "9px",
                        }}
                      >
                        #{row.id}
                      </TableCell>
                      <TableCell className={styles.tableData}>
                        {convertDate(row.date, "en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }).replace(",", "")}
                      </TableCell>
                      <TableCell className={styles.tableData}>
                        {row.description}
                      </TableCell>
                      <TableCell className={styles.tableData} align="center">
                        ${(parseFloat(row.amount) || 0).toFixed(2)}
                      </TableCell>
                      <TableCell
                        className={classNames(styles.tableData)}
                        align="center"
                      >
                        <Box className={styles.tag}>{row.entry_type}</Box>
                      </TableCell>
                      <TableCell
                        className={classNames(styles.tableData, styles.link)}
                        align="center"
                        sx={{
                          borderTopRightRadius: "9px",
                          borderBottomRightRadius: "9px",
                        }}
                      >
                        <Link
                          href={row.invoice_download_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="hover"
                        >
                          Download PDF
                        </Link>
                      </TableCell>
                    </TableRow>
                    <br />
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography className={styles.noBillingHistory}>
          No billings history found!
        </Typography>
      )}
    </Box>
  );
};

export default BillingHistory;

import { PropertyFieldConfig } from "../PropertyFieldConfig";

export const StandAlonePropertyDetails: PropertyFieldConfig[] = [
  {
    fieldId: "type",
    fieldLabel: "Type",
    fieldConfig: {
      type: "select",
      placeholder: "Property Type",
      options: [
        { value: "010", label: "Residential" },
        { value: "020", label: "Commercial" },
      ],
      value: "",
      validation: { required: true },
    },
  },
  {
    fieldId: "compoundId",
    fieldLabel: "Compound ID",
    fieldConfig: {
      type: "text",
      placeholder: "#AB-12345",
      value: "",
      validation: { required: true },
      helpText: "Auto-generated",
    },
  },
  {
    fieldId: "localAuthorityId",
    fieldLabel: "Local Authority ID",
    fieldConfig: {
      type: "text",
      placeholder: "LA-98765",
      value: "",
      validation: { required: true },
      helpText: "Optional",
    },
  },
  {
    fieldId: "currentOccupantName",
    fieldLabel: "Current Occupant Name",
    fieldConfig: {
      type: "text",
      placeholder: "Enter Current Occupant Name",
      value: "",
      validation: { required: false },
    },
  },
  {
    fieldId: "occupied",
    fieldLabel: "Occupied",
    fieldConfig: {
      type: "text",
      placeholder: "Yes/No",
      value: "",
      validation: { required: false },
    },
  },
  {
    fieldId: "tenancyContract",
    fieldLabel: "Tenancy Contract",
    fieldConfig: {
      type: "date",
      placeholder: "31/08/2024",
      value: "",
      validation: { required: false },
    },
  },
  {
    fieldId: "country",
    fieldLabel: "Country",
    fieldConfig: {
      type: "select",
      placeholder: "United Arab Emirates",
      options: [{ value: "UAE", label: "United Arab Emirates" }],
      value: "",
      validation: { required: true },
    },
  },
  {
    fieldId: "city",
    fieldLabel: "City",
    fieldConfig: {
      type: "select",
      placeholder: "Dubai",
      options: [{ value: "Dubai", label: "Dubai" }],
      value: "",
      validation: { required: true },
    },
  },
  {
    fieldId: "street",
    fieldLabel: "Street",
    fieldConfig: {
      type: "text",
      placeholder: "Sheikh Zayed Road",
      value: "",
      validation: { required: true },
    },
  },
  {
    fieldId: "gpsCoordinates",
    fieldLabel: "GPS Coordinates",
    fieldConfig: {
      type: "text",
      placeholder: "25.2048° N, 55.2708° E",
      value: "",
      validation: { required: true },
    },
  },
  {
    fieldId: "communityName",
    fieldLabel: "Community Name",
    fieldConfig: {
      type: "text",
      placeholder: "4",
      value: "",
      validation: { required: true },
      helpText: "Optional",
    },
  },
  {
    fieldId: "numberOfRooms",
    fieldLabel: "Number of Rooms",
    fieldConfig: {
      type: "number",
      placeholder: "4",
      value: "",
      validation: { required: false },
    },
  },
  {
    fieldId: "numberOfMasterBedrooms",
    fieldLabel: "Number of Master Bedrooms",
    fieldConfig: {
      type: "number",
      placeholder: "2",
      value: "",
      validation: { required: false },
    },
  },
  {
    fieldId: "numberOfToilets",
    fieldLabel: "Number of Toilets",
    fieldConfig: {
      type: "number",
      placeholder: "4",
      value: "",
      validation: { required: false },
    },
  },
  {
    fieldId: "totalArea",
    fieldLabel: "Total Area (sq ft)",
    fieldConfig: {
      type: "text",
      placeholder: "3000 sq ft",
      value: "",
      validation: { required: false },
    },
  },
  {
    fieldId: "features",
    fieldLabel: "Features",
    fieldConfig: {
      type: "checkbox",
      value: "",
      options: [
        { value: false, label: "Gym" },
        { value: false, label: "Swimming Pool" },
        { value: false, label: "Sea View" },
      ],
    },
  },
];

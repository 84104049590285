import React from "react";

interface PlanProps {
  width?: number;
  height?: number;
  fill?: string;
}

const Plan: React.FC<PlanProps> = ({
  width = 25,
  height = 25,
  fill = "#7F7F7F",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.11816 20.25C2.11816 20.4489 2.19718 20.6397 2.33783 20.7803C2.47849 20.921 2.66925 21 2.86816 21H22.3682C22.5671 21 22.7578 20.921 22.8985 20.7803C23.0391 20.6397 23.1182 20.4489 23.1182 20.25V11.1562H2.11816V20.25ZM5.21191 13.7812C5.21191 13.6818 5.25142 13.5864 5.32175 13.5161C5.39208 13.4458 5.48746 13.4062 5.58691 13.4062H9.89941C9.99887 13.4062 10.0943 13.4458 10.1646 13.5161C10.2349 13.5864 10.2744 13.6818 10.2744 13.7812V16.7812C10.2744 16.8807 10.2349 16.9761 10.1646 17.0464C10.0943 17.1167 9.99887 17.1562 9.89941 17.1562H5.58691C5.48746 17.1562 5.39208 17.1167 5.32175 17.0464C5.25142 16.9761 5.21191 16.8807 5.21191 16.7812V13.7812ZM22.3682 4.5H2.86816C2.66925 4.5 2.47849 4.57902 2.33783 4.71967C2.19718 4.86032 2.11816 5.05109 2.11816 5.25V8.34375H23.1182V5.25C23.1182 5.05109 23.0391 4.86032 22.8985 4.71967C22.7578 4.57902 22.5671 4.5 22.3682 4.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default Plan;

import React from "react";

interface LogoutProps extends React.SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const Logout: React.FC<LogoutProps> = ({
  width = "13",
  height = "13",
  fill = "#7F7F7F",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 13 13"
    fill="none"
    {...props}
  >
    <path
      d="M2.70833 2.70833H5.95833C6.25625 2.70833 6.5 2.46458 6.5 2.16667C6.5 1.86875 6.25625 1.625 5.95833 1.625H2.70833C2.1125 1.625 1.625 2.1125 1.625 2.70833V10.2917C1.625 10.8875 2.1125 11.375 2.70833 11.375H5.95833C6.25625 11.375 6.5 11.1313 6.5 10.8333C6.5 10.5354 6.25625 10.2917 5.95833 10.2917H2.70833V2.70833Z"
      fill={fill}
    />
    <path
      d="M11.1854 6.31042L9.67417 4.79917C9.63649 4.76047 9.58813 4.73389 9.53527 4.72283C9.4824 4.71178 9.42744 4.71675 9.37741 4.73711C9.32739 4.75747 9.28457 4.79229 9.25445 4.83712C9.22433 4.88195 9.20827 4.93475 9.20833 4.98876V5.95834H5.41667C5.11875 5.95834 4.875 6.20209 4.875 6.50001C4.875 6.79792 5.11875 7.04167 5.41667 7.04167H9.20833V8.01126C9.20833 8.25501 9.50083 8.37417 9.66875 8.20084L11.18 6.68959C11.2883 6.58667 11.2883 6.41334 11.1854 6.31042Z"
      fill={fill}
    />
  </svg>
);

export default Logout;

export const getInputType = (
  type: "text" | "password" | "email",
  showPassword: boolean,
): "text" | "password" | "email" => {
  if (type === "password") {
    return showPassword ? "text" : "password";
  }
  return type;
};

export const convertDate = (
  dateInput: string | null,
  locale: string = "en-US",
  options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  },
): string => {
  if (!dateInput) {
    return "Invalid Date";
  }

  const date = new Date(dateInput);

  return date.toLocaleDateString(locale, options);
};

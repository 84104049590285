import React from "react";

interface BriefcaseFillProps extends React.SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const BriefcaseFill: React.FC<BriefcaseFillProps> = ({
  width = "52",
  height = "52",
  fill = "#001283",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.09543 18.2329L26 0.865247L49.9046 18.2329L40.7738 46.3344H11.2262L2.09543 18.2329Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3333 22.667C18.873 22.667 18.4999 23.0401 18.4999 23.5003V31.8337C18.4999 32.2939 18.873 32.667 19.3333 32.667H32.6666C33.1268 32.667 33.4999 32.2939 33.4999 31.8337V23.5003C33.4999 23.0401 33.1268 22.667 32.6666 22.667H19.3333ZM16.8333 23.5003C16.8333 22.1196 17.9525 21.0003 19.3333 21.0003H32.6666C34.0473 21.0003 35.1666 22.1196 35.1666 23.5003V31.8337C35.1666 33.2144 34.0473 34.3337 32.6666 34.3337H19.3333C17.9525 34.3337 16.8333 33.2144 16.8333 31.8337V23.5003Z"
      fill={"white"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5655 18.3992C23.0343 17.9304 23.6702 17.667 24.3333 17.667H27.6666C28.3296 17.667 28.9655 17.9304 29.4344 18.3992C29.9032 18.8681 30.1666 19.504 30.1666 20.167V33.5003C30.1666 33.9606 29.7935 34.3337 29.3333 34.3337C28.873 34.3337 28.4999 33.9606 28.4999 33.5003V20.167C28.4999 19.946 28.4121 19.734 28.2558 19.5777C28.0996 19.4215 27.8876 19.3337 27.6666 19.3337H24.3333C24.1122 19.3337 23.9003 19.4215 23.744 19.5777C23.5877 19.734 23.4999 19.946 23.4999 20.167V33.5003C23.4999 33.9606 23.1268 34.3337 22.6666 34.3337C22.2063 34.3337 21.8333 33.9606 21.8333 33.5003V20.167C21.8333 19.504 22.0966 18.8681 22.5655 18.3992Z"
      fill={"white"}
    />
  </svg>
);

export { BriefcaseFill };

import React from "react";

interface LocationProps {
  width?: number;
  height?: number;
  fill?: string;
}

const Location: React.FC<LocationProps> = ({
  width = 18.509,
  height = 18.509,
  fill = "#273240",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      d="M8.53847 0.715485C10.1156 0.715485 11.6281 1.34198 12.7432 2.45715C13.8584 3.57232 14.4849 5.08482 14.4849 6.66191C14.4849 9.17466 12.7193 11.9131 9.23374 14.9064C9.03994 15.0729 8.79283 15.1643 8.53733 15.1641C8.28183 15.1639 8.03488 15.072 7.84137 14.9052L7.61083 14.7051C4.27961 11.7899 2.59204 9.11855 2.59204 6.66191C2.59204 5.08482 3.21854 3.57232 4.33371 2.45715C5.44888 1.34198 6.96138 0.715485 8.53847 0.715485ZM8.53847 1.63032C7.20401 1.63032 5.9242 2.16043 4.9806 3.10404C4.03699 4.04765 3.50688 5.32745 3.50688 6.66191C3.50688 8.79775 5.06088 11.2587 8.21157 14.0154L8.43906 14.2124C8.46672 14.2361 8.50199 14.2492 8.53847 14.2492C8.57495 14.2492 8.61022 14.2361 8.63788 14.2124C11.941 11.3751 13.5701 8.84837 13.5701 6.66191C13.5701 6.00115 13.4399 5.34687 13.1871 4.73641C12.9342 4.12594 12.5636 3.57127 12.0963 3.10404C11.6291 2.63681 11.0744 2.26619 10.464 2.01333C9.85352 1.76047 9.19923 1.63032 8.53847 1.63032ZM8.53847 4.37483C9.14504 4.37483 9.72677 4.61579 10.1557 5.0447C10.5846 5.47361 10.8256 6.05534 10.8256 6.66191C10.8256 7.26849 10.5846 7.85022 10.1557 8.27913C9.72677 8.70804 9.14504 8.949 8.53847 8.949C7.9319 8.949 7.35017 8.70804 6.92125 8.27913C6.49234 7.85022 6.25138 7.26849 6.25138 6.66191C6.25138 6.05534 6.49234 5.47361 6.92125 5.0447C7.35017 4.61579 7.9319 4.37483 8.53847 4.37483ZM8.53847 5.28966C8.17453 5.28966 7.82549 5.43424 7.56814 5.69158C7.31079 5.94893 7.16622 6.29797 7.16622 6.66191C7.16622 7.02586 7.31079 7.3749 7.56814 7.63224C7.82549 7.88959 8.17453 8.03417 8.53847 8.03417C8.90241 8.03417 9.25145 7.88959 9.5088 7.63224C9.76615 7.3749 9.91072 7.02586 9.91072 6.66191C9.91072 6.29797 9.76615 5.94893 9.5088 5.69158C9.25145 5.43424 8.90241 5.28966 8.53847 5.28966Z"
      fill={fill}
    />
  </svg>
);

export default Location;

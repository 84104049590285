import React from "react";

interface TenantProps {
  width?: string;
  height?: string;
  fill?: string;
}

const Tenant: React.FC<TenantProps> = ({
  width = "25px",
  height = "24px",
  fill = "#7F7F7F",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M6.61816 21C6.05416 21 5.57983 20.8077 5.19516 20.423C4.8105 20.0383 4.61816 19.564 4.61816 19V17.846C4.61816 17.402 4.7765 17.022 5.09316 16.706C5.40983 16.39 5.78983 16.2317 6.23316 16.231H7.61816V4.616C7.61816 4.17133 7.7765 3.791 8.09316 3.475C8.40983 3.159 8.78983 3.00067 9.23316 3H19.0032C19.4472 3 19.8272 3.15833 20.1432 3.475C20.4592 3.79167 20.6175 4.17167 20.6182 4.615V19C20.6182 19.564 20.4258 20.0383 20.0412 20.423C19.6565 20.8077 19.1822 21 18.6182 21H6.61816ZM18.6182 20C18.9015 20 19.1392 19.904 19.3312 19.712C19.5232 19.52 19.6188 19.2827 19.6182 19V4.616C19.6182 4.436 19.5605 4.28833 19.4452 4.173C19.3298 4.05767 19.1825 4 19.0032 4H9.23316C9.05383 4 8.9065 4.05767 8.79116 4.173C8.67583 4.28833 8.61816 4.436 8.61816 4.616V16.23H16.0032C16.4472 16.23 16.8272 16.388 17.1432 16.704C17.4592 17.02 17.6175 17.4007 17.6182 17.846V19C17.6182 19.2833 17.7142 19.521 17.9062 19.713C18.0982 19.905 18.3355 20.0007 18.6182 20ZM10.5032 8.385C10.3612 8.385 10.2422 8.337 10.1462 8.241C10.0508 8.145 10.0032 8.026 10.0032 7.884C10.0032 7.742 10.0508 7.62333 10.1462 7.528C10.2415 7.43267 10.3605 7.385 10.5032 7.385H17.7332C17.8752 7.385 17.9942 7.433 18.0902 7.529C18.1862 7.625 18.2338 7.74367 18.2332 7.885C18.2325 8.02633 18.1848 8.145 18.0902 8.241C17.9955 8.337 17.8765 8.385 17.7332 8.385H10.5032ZM10.5032 11.154C10.3612 11.154 10.2422 11.106 10.1462 11.01C10.0508 10.914 10.0032 10.795 10.0032 10.653C10.0032 10.511 10.0508 10.3923 10.1462 10.297C10.2415 10.2017 10.3605 10.154 10.5032 10.154H17.7332C17.8752 10.154 17.9942 10.202 18.0902 10.298C18.1862 10.394 18.2338 10.513 18.2332 10.655C18.2325 10.797 18.1848 10.9157 18.0902 11.011C17.9955 11.1063 17.8765 11.154 17.7332 11.154H10.5032Z"
        fill={fill}
      />
    </svg>
  );
};

export default Tenant;

import apiClient from "./apiClient";

const SUBSCRIPTION_PLANS_URL = "/api/platform-admin/subscription-plans/";
const SUBSCRIPTIONS_URL = "/api/platform-admin/subscriptions/";
const BILLING_HISTORY_URL =
  "/api/platform-admin/subscriptions/{subscriptionId}/billing-history/";

export const getSubscriptionPlans = async () => {
  const originalAuthHeader = apiClient.defaults.headers.common["Authorization"];

  try {
    delete apiClient.defaults.headers.common["Authorization"];

    const response = await apiClient.get(SUBSCRIPTION_PLANS_URL);
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription plans:", error);
    throw error;
  } finally {
    if (originalAuthHeader) {
      apiClient.defaults.headers.common["Authorization"] = originalAuthHeader;
    } else {
      delete apiClient.defaults.headers.common["Authorization"];
    }
  }
};

export const getSubscriptions = async () => {
  const originalAuthHeader = apiClient.defaults.headers.common["Authorization"];

  try {
    delete apiClient.defaults.headers.common["Authorization"];
    const response = await apiClient.get(SUBSCRIPTIONS_URL);
    return response.data;
  } catch (error) {
    console.error("Error fetching subscriptions:", error);
    throw error;
  } finally {
    if (originalAuthHeader) {
      apiClient.defaults.headers.common["Authorization"] = originalAuthHeader;
    } else {
      delete apiClient.defaults.headers.common["Authorization"];
    }
  }
};

export interface Transaction {
  id: number;
  reference: string;
  date: string;
  type: "Invoice" | string;
  description: string;
  currency: string;
  amount: string;
  entry_type: "Debit" | "Credit";
  invoice_download_link: string;
}

const getBillingHistory = async (subscriptionId: number) => {
  const url = BILLING_HISTORY_URL.replace(
    "{subscriptionId}",
    subscriptionId.toString(),
  );

  try {
    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default getBillingHistory;

const CANCELLATION_REASONS_URL = "/api/platform-admin/cancellation-reasons/";

export const getCancellationReasons = async () => {
  try {
    const response = await apiClient.get(CANCELLATION_REASONS_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const CANCEL_SUBSCRIPTION_URL = (subscriptionId: number) =>
  `/api/platform-admin/subscriptions/${subscriptionId}/cancel/`;

interface CancelSubscriptionData {
  cancellation_reason: number;
  memo?: string;
}

export const cancelSubscription = async (
  subscriptionId: number,
  data: CancelSubscriptionData,
) => {
  try {
    const response = await apiClient.post(
      CANCEL_SUBSCRIPTION_URL(subscriptionId),
      data,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

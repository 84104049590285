import { Typography, Box } from "@mui/material";
import CancelSubscriptionIcon from "../../../../../../assets/icons/ui/CancelSubscription";
import { convertDate } from "../../../../../../utils/inputFIeldHelper";
import styles from "./CancelSubscription.module.css";

interface CancelSubscriptionConfirmProps {
  isRequested?: boolean;
  endDate?: string | null;
  endOfBillingCycle?: string;
}

const CancelSubscription: React.FC<CancelSubscriptionConfirmProps> = ({
  isRequested = false,
  endDate = "N/A",
  endOfBillingCycle = "N/A",
}) => {
  const message = isRequested
    ? `A cancellation request is already being processed for this subscription. Your subscription will remain active until ${convertDate(
        endDate,
      )}.`
    : `Are you sure you want to cancel your subscription? You'll lose access to premium features after ${convertDate(
        endOfBillingCycle,
      )}.`;

  return (
    <Box className={styles.section}>
      <CancelSubscriptionIcon />
      <Typography className={styles.infoText}>{message}</Typography>
    </Box>
  );
};

export default CancelSubscription;

// This file will be removed once data is fetched from the backend API.

interface ValidationConfig {
  required: boolean;
}

export interface TenanetFieldConfig {
  fieldId: string;
  fieldLabel: string;
  fieldConfig: {
    type: "text" | "number" | "select" | "checkbox" | "date";
    placeholder?: string;
    value: any;
    validation?: ValidationConfig;
    options?: { value: any; label: string }[];
    helpText?: string;
  };
}

export const TenantFields: { [key: string]: TenanetFieldConfig[] } = {
  "Property Details": [
    {
      fieldId: "buildingName",
      fieldLabel: "Building/Compound Name:",
      fieldConfig: {
        type: "text",
        placeholder: "Sunnyvale Towers",
        value: "",
        validation: { required: true },
      },
    },
    {
      fieldId: "unitNumber",
      fieldLabel: "Apartment/Unit number:",
      fieldConfig: {
        type: "number",
        placeholder: "2038",
        value: "",
        validation: { required: true },
      },
    },
  ],
  "Tenant Details": [
    {
      fieldId: "tenantName",
      fieldLabel: "Tenant name:",
      fieldConfig: {
        type: "text",
        placeholder: "Jessica Taylor",
        value: "",
        validation: { required: true },
      },
    },
    {
      fieldId: "ejariNumber",
      fieldLabel: "Ejari/Municipality Reference number:",
      fieldConfig: {
        type: "text",
        placeholder: "EJ123456789",
        value: "",
        validation: { required: true },
      },
    },
  ],
  "Lease Details": [
    {
      fieldId: "contractStartDate",
      fieldLabel: "Contract Start Date:",
      fieldConfig: {
        type: "date",
        placeholder: "31/08/2024",
        value: "",
        validation: { required: true },
      },
    },
    {
      fieldId: "contractEndDate",
      fieldLabel: "Contract End Date:",
      fieldConfig: {
        type: "date",
        placeholder: "31/08/2025",
        value: "",
        validation: { required: true },
      },
    },
    {
      fieldId: "yearlyRental",
      fieldLabel: "Yearly rental",
      fieldConfig: {
        type: "number",
        placeholder: "$18,000",
        value: "",
        validation: { required: true },
        helpText: "RNTR",
      },
    },
    {
      fieldId: "serviceCharges",
      fieldLabel: "Service Charges",
      fieldConfig: {
        type: "number",
        placeholder: "$500",
        value: "",
        validation: { required: true },
        helpText: "RNTR",
      },
    },
  ],
  "Payment Details": [
    {
      fieldId: "dateSubmitted",
      fieldLabel: "Date submitted:",
      fieldConfig: {
        type: "date",
        placeholder: "01/01/2024",
        value: "",
        validation: { required: true },
      },
    },
    {
      fieldId: "paymentMethod",
      fieldLabel: "Payment Method:",
      fieldConfig: {
        type: "select",
        placeholder: "Cheque",
        value: "Cheque",
        validation: { required: true },
        options: [{ label: "Cheque", value: "Cheque" }],
        helpText: "cash or cheque",
      },
    },
  ],
  "Cheque Details": [
    {
      fieldId: "chequeName",
      fieldLabel: "Name on Cheque:",
      fieldConfig: {
        type: "text",
        placeholder: "Jessica Taylor",
        value: "",
        validation: { required: true },
      },
    },
    {
      fieldId: "bankName",
      fieldLabel: "Bank Name:",
      fieldConfig: {
        type: "text",
        placeholder: "Wells Fargo",
        value: "",
        validation: { required: true },
      },
    },
  ],
};

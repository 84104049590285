import React from "react";

interface SettingsProps {
  width?: string;
  height?: string;
  fill?: string;
}

const Settings: React.FC<SettingsProps> = ({
  width = 25,
  height = 25,
  fill = "#7F7F7F",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M9.9645 19.488L9.92735 19.1908L9.64785 19.0833C9.4581 19.0103 9.28068 18.9234 9.115 18.8227C8.93512 18.7134 8.75951 18.5962 8.58815 18.4711L8.36004 18.3046L8.09968 18.414L5.53051 19.4935L3.22245 15.5069L5.44521 13.8236L5.67998 13.6458L5.63833 13.3543C5.62474 13.2591 5.61836 13.1701 5.61836 13.087V12.413C5.61836 12.3299 5.62474 12.2409 5.63833 12.1457L5.67998 11.8542L5.44521 11.6764L3.22245 9.99314L5.53051 6.00648L8.09968 7.08596L8.35951 7.19514L8.58745 7.02937C8.75691 6.90612 8.93449 6.79028 9.1203 6.68189C9.3024 6.57566 9.48252 6.48576 9.66067 6.41154L9.92851 6.29994L9.9645 6.01202L10.3098 3.25H14.927L15.2722 6.01202L15.3094 6.30917L15.5889 6.41667C15.7786 6.48965 15.956 6.57663 16.1217 6.6773C16.3016 6.7866 16.4772 6.90378 16.6486 7.02886L16.8767 7.19536L17.137 7.08596L19.7062 6.00648L22.0143 9.99314L19.7915 11.6764L19.5567 11.8542L19.5984 12.1457C19.612 12.2409 19.6184 12.3299 19.6184 12.413V13.087C19.6184 13.1442 19.6096 13.2105 19.5876 13.2876L19.4935 13.6169L19.7665 13.8236L21.9893 15.5069L19.6819 19.4924L17.1384 18.4146L16.878 18.3043L16.6493 18.4706C16.4798 18.5939 16.3022 18.7097 16.1164 18.8181C15.9343 18.9243 15.7542 19.0142 15.576 19.0885L15.3082 19.2001L15.2722 19.488L14.927 22.25H10.3098L9.9645 19.488ZM12.6684 16.75C13.7676 16.75 14.7198 16.3557 15.4969 15.5786L9.16836 12.75H8.66836C8.66836 12.7501 8.66836 12.7502 8.66836 12.7503C8.66844 13.8474 9.05689 14.7988 9.82456 15.5763C10.5957 16.3574 11.5544 16.75 12.6684 16.75Z"
      fill={fill}
    />
  </svg>
);

export default Settings;

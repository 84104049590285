import React from "react";

interface MultiplePropertyProps {
  height?: string;
  width?: string;
  fill?: string;
}

const MultipleProperty: React.FC<MultiplePropertyProps> = ({
  height = "19px",
  width = "19px",
  fill = "black",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      style={{ flexShrink: 0 }}
    >
      <g clipPath="url(#clip0_1176_2668)">
        <path
          d="M8.53135 2.02323C8.32038 1.81233 8.03428 1.69385 7.73597 1.69385C7.43766 1.69385 7.15156 1.81233 6.9406 2.02323L0.587721 8.37498C0.482098 8.48061 0.42276 8.62386 0.42276 8.77323C0.42276 8.92261 0.482098 9.06586 0.587721 9.17148C0.693343 9.27711 0.836598 9.33644 0.98597 9.33644C1.13534 9.33644 1.2786 9.27711 1.38422 9.17148L1.54847 9.00611V14.9607C1.54847 15.4083 1.72626 15.8375 2.04273 16.154C2.3592 16.4704 2.78842 16.6482 3.23597 16.6482H3.85472C3.81713 16.4631 3.79828 16.2746 3.79847 16.0857V11.4901C3.52974 11.395 3.28948 11.2334 3.10011 11.0203C2.91074 10.8073 2.77843 10.5497 2.71552 10.2717C2.65261 9.99365 2.66116 9.70422 2.74037 9.43038C2.81957 9.15655 2.96685 8.90725 3.16847 8.70573L9.1906 2.68248L8.53135 2.02323Z"
          fill={fill}
        />
        <path
          d="M10.3156 3.14823C10.5266 2.93733 10.8127 2.81885 11.111 2.81885C11.4093 2.81885 11.6954 2.93733 11.9063 3.14823L13.9235 5.16536V3.71073C13.9235 3.56155 13.9827 3.41847 14.0882 3.31299C14.1937 3.2075 14.3368 3.14823 14.486 3.14823H15.611C15.7602 3.14823 15.9032 3.2075 16.0087 3.31299C16.1142 3.41847 16.1735 3.56155 16.1735 3.71073V7.41536L18.2592 9.49998C18.3648 9.60561 18.4242 9.74886 18.4242 9.89823C18.4242 10.0476 18.3648 10.1909 18.2592 10.2965C18.1536 10.4021 18.0103 10.4614 17.861 10.4614C17.7116 10.4614 17.5683 10.4021 17.4627 10.2965L17.2985 10.1311V16.0857C17.2985 16.5333 17.1207 16.9625 16.8042 17.279C16.4877 17.5954 16.0585 17.7732 15.611 17.7732H6.61097C6.16342 17.7732 5.7342 17.5954 5.41773 17.279C5.10126 16.9625 4.92347 16.5333 4.92347 16.0857V10.1311L4.75922 10.2965C4.70692 10.3488 4.64483 10.3903 4.5765 10.4186C4.50817 10.4469 4.43493 10.4614 4.36097 10.4614C4.28701 10.4614 4.21377 10.4469 4.14544 10.4186C4.07711 10.3903 4.01502 10.3488 3.96272 10.2965C3.91042 10.2442 3.86894 10.1821 3.84063 10.1138C3.81233 10.0454 3.79776 9.97219 3.79776 9.89823C3.79776 9.82427 3.81233 9.75103 3.84063 9.6827C3.86894 9.61437 3.91042 9.55228 3.96272 9.49998L10.3156 3.14823Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1176_2668">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.423477 0.898193)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MultipleProperty;

import React from "react";

interface MessageProps {
  width?: number;
  height?: number;
  fill?: string;
}

const Message: React.FC<MessageProps> = ({
  width = 25,
  height = 24,
  fill = "#7F7F7F",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_985_877)">
        <path
          d="M2.61817 8.99401C2.61764 8.20621 2.77252 7.42605 3.07393 6.69819C3.37535 5.97034 3.81738 5.30909 4.37471 4.75231C4.93205 4.19553 5.59374 3.75416 6.3219 3.45348C7.05005 3.15279 7.83037 2.99869 8.61817 3.00001H16.6182C19.9312 3.00001 22.6182 5.69501 22.6182 8.99401V21H8.61817C5.30517 21 2.61817 18.305 2.61817 15.006V8.99401ZM20.6182 19V8.99401C20.6155 7.93451 20.1931 6.91925 19.4433 6.17063C18.6936 5.42201 17.6777 5.00106 16.6182 5.00001H8.61817C8.09301 4.99869 7.57277 5.10106 7.08726 5.30123C6.60176 5.50141 6.16054 5.79546 5.78893 6.16652C5.41731 6.53758 5.1226 6.97835 4.92169 7.46356C4.72078 7.94876 4.61764 8.46886 4.61817 8.99401V15.006C4.62081 16.0655 5.04328 17.0808 5.79302 17.8294C6.54277 18.578 7.55866 18.999 8.61817 19H20.6182ZM14.6182 11H16.6182V13H14.6182V11ZM8.61817 11H10.6182V13H8.61817V11Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_985_877">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.618164)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Message;

import React from "react";

interface GridProps {
  width?: number;
  height?: number;
  fill?: string;
}

const Grid: React.FC<GridProps> = ({
  width = 18,
  height = 18,
  fill = "#7F7F7F",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.218262 1.8001C0.218262 1.48184 0.34469 1.17661 0.569734 0.951569C0.794777 0.726526 1.1 0.600098 1.41826 0.600098H15.8183C16.1365 0.600098 16.4417 0.726526 16.6668 0.951569C16.8918 1.17661 17.0183 1.48184 17.0183 1.8001V4.2001C17.0183 4.51836 16.8918 4.82358 16.6668 5.04863C16.4417 5.27367 16.1365 5.4001 15.8183 5.4001H1.41826C1.1 5.4001 0.794777 5.27367 0.569734 5.04863C0.34469 4.82358 0.218262 4.51836 0.218262 4.2001V1.8001ZM0.218262 9.0001C0.218262 8.68184 0.34469 8.37661 0.569734 8.15157C0.794777 7.92653 1.1 7.8001 1.41826 7.8001H8.61826C8.93652 7.8001 9.24174 7.92653 9.46679 8.15157C9.69183 8.37661 9.81826 8.68184 9.81826 9.0001V16.2001C9.81826 16.5184 9.69183 16.8236 9.46679 17.0486C9.24174 17.2737 8.93652 17.4001 8.61826 17.4001H1.41826C1.1 17.4001 0.794777 17.2737 0.569734 17.0486C0.34469 16.8236 0.218262 16.5184 0.218262 16.2001V9.0001ZM13.4183 7.8001C13.1 7.8001 12.7948 7.92653 12.5697 8.15157C12.3447 8.37661 12.2183 8.68184 12.2183 9.0001V16.2001C12.2183 16.5184 12.3447 16.8236 12.5697 17.0486C12.7948 17.2737 13.1 17.4001 13.4183 17.4001H15.8183C16.1365 17.4001 16.4417 17.2737 16.6668 17.0486C16.8918 16.8236 17.0183 16.5184 17.0183 16.2001V9.0001C17.0183 8.68184 16.8918 8.37661 16.6668 8.15157C16.4417 7.92653 16.1365 7.8001 15.8183 7.8001H13.4183Z"
        fill={fill}
      />
    </svg>
  );
};

export default Grid;

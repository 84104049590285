import React from "react";

interface SinglePropertyProps {
  height?: string;
  width?: string;
  fill?: string;
}

const SingleProperty: React.FC<SinglePropertyProps> = ({
  height = "15",
  width = "14",
  fill = "#001283",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <g clipPath="url(#clip0_1176_2661)">
        <path
          d="M10.2188 2.63676C10.0079 2.42585 9.72178 2.30737 9.42347 2.30737C9.12516 2.30737 8.83906 2.42585 8.6281 2.63676L1.15022 10.1135C1.09792 10.1658 1.05644 10.2279 1.02813 10.2962C0.999828 10.3646 0.98526 10.4378 0.98526 10.5118C0.98526 10.5857 0.999828 10.659 1.02813 10.7273C1.05644 10.7956 1.09792 10.8577 1.15022 10.91C1.25584 11.0156 1.3991 11.075 1.54847 11.075C1.62243 11.075 1.69567 11.0604 1.764 11.0321C1.83233 11.0038 1.89442 10.9623 1.94672 10.91L9.42347 3.43213L16.9002 10.91C17.0058 11.0156 17.1491 11.075 17.2985 11.075C17.4478 11.075 17.5911 11.0156 17.6967 10.91C17.8023 10.8044 17.8617 10.6611 17.8617 10.5118C17.8617 10.3624 17.8023 10.2191 17.6967 10.1135L15.0485 7.46638V3.76176C15.0485 3.61257 14.9892 3.4695 14.8837 3.36401C14.7782 3.25852 14.6352 3.19926 14.486 3.19926H13.361C13.2118 3.19926 13.0687 3.25852 12.9632 3.36401C12.8577 3.4695 12.7985 3.61257 12.7985 3.76176V5.21638L10.2188 2.63676Z"
          fill="#001283"
        />
        <path
          d="M9.42348 4.65381L16.1735 11.4038V16.1367C16.1735 16.5842 15.9957 17.0135 15.6792 17.3299C15.3628 17.6464 14.9335 17.8242 14.486 17.8242H4.36098C3.91342 17.8242 3.4842 17.6464 3.16773 17.3299C2.85127 17.0135 2.67348 16.5842 2.67348 16.1367V11.4038L9.42348 4.65381Z"
          fill="#001283"
        />
      </g>
      <defs>
        <clipPath id="clip0_1176_2661">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.423477 0.949219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SingleProperty;

import React from "react";

interface FailedMailProps {
  width?: string;
  height?: string;
}

const FailedMail: React.FC<FailedMailProps> = ({
  width = "235px",
  height = "235px",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 235 235"
      fill="none"
    >
      <path
        d="M212.969 75.6406C208.562 71.9688 203.422 69.7656 198.281 69.7656H36.7188C31.5781 69.7656 26.4375 71.9687 22.0312 74.9062C19.8281 77.1094 17.625 79.3125 16.1563 82.25C15.4219 83.7187 15.4219 85.1875 14.6875 86.6563V198.281C14.6875 210.766 24.2344 220.312 36.7188 220.312H198.281C210.766 220.312 220.312 210.766 220.312 198.281V92.5313C220.312 85.9219 217.375 80.0469 212.969 75.6406Z"
        fill="#FEC111"
      />
      <path
        d="M215.172 82.9844V80.0469C215.172 79.3125 215.172 78.5781 214.438 77.8438C214.438 77.8438 214.438 77.1094 213.703 77.1094C213.703 76.375 212.969 75.6406 212.234 75.6406C210.766 74.1719 209.297 73.4375 207.828 72.7031L129.984 18.3594C126.313 16.1562 121.906 14.6875 117.5 14.6875C113.094 14.6875 108.688 16.1562 105.016 18.3594L27.1719 72.7031C25.7031 73.4375 23.5 74.1719 22.0312 75.6406C20.5625 77.1094 19.8281 78.5781 19.8281 80.0469V81.5156C19.8281 82.9844 20.5625 85.1875 22.0312 85.9219C23.5 87.3906 24.9687 88.125 26.4375 88.8594L104.281 143.203C107.953 146.141 112.359 146.875 116.766 146.875C121.172 146.875 125.578 145.406 129.25 143.203L207.828 88.8594C209.297 88.125 210.766 87.3906 212.234 85.9219L213.703 84.4531L214.438 83.7188C214.438 83.7188 214.438 82.9844 215.172 82.9844Z"
        fill="#F9A01B"
      />
      <path
        d="M105.016 143.938L27.1719 89.5938C25.7031 88.8594 23.5 88.125 22.0312 86.6563C20.5625 85.1875 19.8281 83.7188 19.8281 82.25V80.7813C19.8281 79.3125 20.5625 77.1094 22.0312 76.375C19.8281 78.5781 17.625 80.7812 16.1563 83.7188C15.4219 85.1875 15.4219 86.6563 14.6875 88.125V198.281C14.6875 210.766 24.2344 220.312 36.7188 220.312H117.5V147.609C113.094 147.609 108.688 146.875 105.016 143.938Z"
        fill="#FCB316"
      />
      <path
        d="M176.25 132.188C152.016 132.188 132.188 152.016 132.188 176.25C132.188 200.484 152.016 220.312 176.25 220.312C200.484 220.312 220.312 200.484 220.312 176.25C220.312 152.016 200.484 132.188 176.25 132.188Z"
        fill="#D41F26"
      />
      <path
        d="M196.078 156.422C193.141 153.484 188.734 153.484 185.797 156.422L176.25 165.969L166.703 156.422C163.766 153.484 159.359 153.484 156.422 156.422C153.484 159.359 153.484 163.766 156.422 166.703L165.969 176.25L156.422 185.797C153.484 188.734 153.484 193.141 156.422 196.078C157.891 197.547 160.094 198.281 161.563 198.281C163.031 198.281 165.234 197.547 166.703 196.078L176.25 186.531L185.797 196.078C187.266 197.547 189.469 198.281 190.938 198.281C192.406 198.281 194.609 197.547 196.078 196.078C199.016 193.141 199.016 188.734 196.078 185.797L186.531 176.25L196.078 166.703C199.016 163.766 199.016 159.359 196.078 156.422Z"
        fill="white"
      />
    </svg>
  );
};

export default FailedMail;
